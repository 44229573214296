import React, {useEffect, useState} from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import './index.css';


let baseURL = '';

  if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:5000';
  } else {
    baseURL = 'http://83.223.113.92:5000';
  }

function Cart(){
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state ;
  const [billItem,setBillItem] = useState([]);
  const [row,setRow] = useState();
  const [refresh, setRefresh] = useState(false)

  useEffect(()=>{
    const getItem = async(e) =>{
      const res = await fetch(baseURL+"/api/order");
      const result = await res.json();
      setBillItem(result);
    }
    getItem();
  },[])

  const removeCart = async(index) =>{
    const rows = [...billItem];
    rows.splice(index, 1);
    setBillItem(rows);

    if(window.confirm("Are You Sure To Cancle Item")){
      const res = await fetch(baseURL+'/api/delorder/'+index,{
        method:"DELETE"
      })
      const data = await res.json()
      console.log(data);
      if(data){
        window.location.reload()
      }
    }
  }
  const loginuser = localStorage.getItem('loginuser');
  const [total, setTotal] = useState(0);

  const calculateTotal = (item) => {
    return item.price * item.quantity;
  };
  
  useEffect(() => {
    let newTotal = 0;
    billItem.map(
      (total, item) => (total.loginuser === loginuser ? newTotal += total.price * total.quantity
      : null)
     
    )
    setTotal(newTotal);
    
  }, [billItem]);
 
  const [name,setName] = useState('');
  const [city,setCity] = useState('');
  const [err,setErr] = useState('');
  
  // const [Bill,setBill] = useState([])
  // useEffect(()=>{
  //   const getBill = async(e) =>{
  //     const res = await fetch("http://localhost:5000/bill");
  //     const result = await res.json();
  //     setBill(result);
  //   }
  //   getBill();
  // },[Bill])

  const increase = async(index) => {
    const temp = [...billItem];
    temp[index].quantity = temp[index].quantity + 1;
    setBillItem(temp);
   
  };
  
  const decrease = async(index) => {
    const temp =  [...billItem];
    if(temp[index].quantity > 1){
      temp[index].quantity = temp[index].quantity - 1;
    }
    setBillItem(temp);
  
  };
  
  
  const handleChangeRow = (e) =>{
   
    setBillItem(e.target.value)
    
  }
 
  const updateCart = async(index) =>{
   // console.log(index);
    const {quantity} = row;
   // console.log(row.quantity)
    const res = await fetch(baseURL+'/api/updateCart/'+index,{
      method: 'PUT',
      headers:{
        "Content-Type" : "application/json"
      },
      body: JSON.stringify({
        quantity 
      })
    })
    const data = await res.json();
    console.log(data);
    if(data){
      window.alert(`update ${row.itemname}'s quantity`)
    }
  }

  const clearData = async(e) =>{
    //console.log(user)
    e.preventDefault();
    const res = await fetch(baseURL+'/api/deleteall',{
      method:'DELETE'
    })
    const data = await res.json();
    console.log(data);
    if(data){
      window.location.reload();
    }
  }

const page = localStorage.getItem("PageNumber");
//   const cartCopy = {...billItem};
// if (!cartCopy[itemname]) {
//   cartCopy[itemname] = 1;
// } else {
//   cartCopy[itemname] += 1;
// }
// setBillItem(cartCopy);

const goback = () =>{
  navigate(`/menu?page=${page}`);
}

    return(
        <>
        <h2 class="text-center">Cart Items</h2>
       
        {/* <h2>{state}</h2> */}
{/*        
   {Bill.map(data => (data.loginuser === loginuser ?  <input type="text" name="name" placeholder="Enter Your Name" class="form-control" id="inputEmail4" onChange={(e)=>setName(e.target.value)} defaultValue={data.name} required/>
   :  <input type="text" name="name" placeholder="Enter Your Name" class="form-control" id="inputEmail4" onChange={(e)=>setName(e.target.value)} value={name} required/>
 ))}
   */}
  
        <table className="table">
            <thead>
                <tr>
               
                    <th>Item Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {billItem.length > 0 ? (
          billItem.map((data, index)=>
          (data.loginuser === loginuser ? 
            <tr key={index}>
            
                <td className="bill">{data.itemname}</td>
                <td className="bill">
               <div className="col-xl-1" style={{width:"25%"}}>
<div class="input-group">

<button class="btn btn-outline-primary" type="button" onClick={() => {decrease(index); setRow(data);}}>-</button>
{/* <div style={{margin: "auto"}}>{data.quantity}</div> */}
<input type="text" class="form-control" name="quantity" value={data.quantity} onChange={(e) => handleChangeRow(index)}/>

<button class="btn btn-outline-primary" type="button" onClick={() => {increase(index);  setRow(data);}}>+</button>

</div>
</div>
               </td>
                <td className="bill">{data.price}</td>
                <td className="bill">{calculateTotal(data)}</td>
                <td className="bill"><button className="btn btn-outline-danger" onClick={() => removeCart(data._id)}>x</button></td>
                <td className="bill"><button className="btn btn-outline-primary" onClick={() =>{ updateCart(data._id); setRow(data);}}>Update</button></td>
            </tr>
        : null)
            
        )
        ) : (
          <div className="col mb-4">
            <h4>Your cart is empty</h4>
          </div>
        )}
  {
                        
                    }    

                     <tr>
      <th id="total" colSpan="3" className="bill">Total Amount:</th>
      <td className="bill">{total}</td>
    </tr>
   

    <tr >
  <td className="bill"> 
 <button className="btn btn-outline-success" onClick={goback} >Go back</button>
        {/* <button className="btn btn-outline-danger" style={{marginLeft: "5px"}} onClick={clearData}>Clear All </button> */}
        <button className="btn btn-outline-primary" style={{marginLeft: "5px"}} onClick={()=> navigate('/invoice')}>Order</button>
  </td>

</tr>


    
        
       
   
      
        </tbody>
        </table>
 
  
  

    
    
    
     
     
           
            
       
       
        </>
    )
}
export default Cart;

// passing values from one page to another in react js on click button