import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//import EditModal from "./EditModal";
import './index.css';
import EditModal from "./EditModal";
import pizza from './pizza.webp';


let baseURL = '';

  if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:5000';
  } else {
    baseURL = 'http://83.223.113.92:5000';
  }
  
function Table({ tableData, removeData, currentPage, Img }) {

  const [open, setOpen] = useState(false);

  const history = useNavigate();
  const [rowData, setRowData] = useState();
  const [count, setCount] = useState(0);

  const handleChange = (e) => {
    const newInput = (data) => ({ ...data, [e.target.name]: e.target.value })
    setRowData(newInput);

  }

  const [editId, setEditId] = useState(-1);


  const handleEdit = async (id) => {
    //console.log(id)
    try {
      const res = await fetch(baseURL+'/api/menu/' + id);
      const result = await res.json();
      setRowData(result[0]);

    } catch (err) { console.log(err) }


    setEditId(id);
  }

  const handleSubmit = async (e) => {
    //e.preventDefault();
    console.log(editId)
    const { itemname, price, Categories } = rowData;
    const result = await fetch(baseURL+'/api/update/' + editId, {
      method: 'PUT',
      headers: {
        'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS'
      },
      body: JSON.stringify({
        itemname, price, Categories
      })
    })
    const data = await result.json();
    console.log(data);
    if (data) {
      setOpen(false)
      window.location.replace(`/menu?page=${currentPage}`);
    }
  }

  useEffect(() => {

  }, [currentPage]);



  const loginuser = localStorage.getItem('loginuser');
  const [login, setLogin] = useState(loginuser);
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState(false);
 

const [billItem,setBillItem] = useState([]);

  useEffect(()=>{
    const getItem = async(e) =>{
      const res = await fetch(baseURL+"/api/order");
      const result = await res.json();
      setBillItem(result);
    }
    getItem();
  },[])

  // console.log(billItem.length)

  const BillSubmit = async (rowData) => {
    if(loginuser){
    const isProductInCart = billItem.some((item) => item.itemname === rowData.itemname && item.loginuser === login);
   // console.log(rowData.length)
 if(isProductInCart){
  window.alert('Item is already in cart')
 }else{
  const { itemname, price, quantity } = rowData;
  const res = await fetch(baseURL+"/api/order", {
    method: "POST",
    headers: { 'Content-Type':'application/json',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS'},
    body: JSON.stringify({
      itemname, price, quantity, loginuser: login
    })
   
  })

  const data = await res.json();
  console.log(data);
  if (data) {
    // history('/cart')
   window.alert("Item Is Added")
   window.location.reload()
  }
 }
  
    }
    

   
  }

 //console.log(tableData)

  const number = localStorage.setItem('count',count);

  if (localStorage.getItem('role') === 'true') {
    return (
      <>

        <table className="table">
          <thead>
            <tr>
              <th>No</th>
              <th>Item Name</th>
              <th>Price</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              tableData.map((d, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{d.itemname}</td>
                    <td>{d.price}</td>
                    {/* <td><img
          src={'/demo123/restaurant/images/'+d.file}
          style={{
            width: "50%",
            height: "10%",
            position: "acsolute"
          }}
        />
</td> */}
                   
                    <td><button className="btn btn-outline-danger" onClick={() => (removeData(d._id))}>x</button></td>
                    <td>
                      <button className="btn btn-outline-warning" onClick={() => {
                        setRowData(d);
                        setOpen(true);
                        handleEdit(d._id)
                      }}>
                        ✐
                      </button>
                    </td>
                  </tr>
                )
              })
            }




          </tbody>
        </table>
        

        {
          open && <EditModal close={() => setOpen(false)} rowData={rowData} handleChange={handleChange} handleSubmit={handleSubmit} />
        }

      </>
    )
  }


  else if (localStorage.getItem('role') === "false") {
    return (
      <>
  {/* <table class="table table-borderless">
      <tr>
        <th></th>
        <th></th>
        <th></th>
      </tr>
     
       
        {tableData.map((data,index)=>{
      return(
        <tr key={index}>
        <td>
      
        <img className='catImg' src={'http://localhost:5000/images/'+data.file} alt="card_img" style={{width: '10%', height: '50%'}} />
         
</td>
</tr>
      )
    })}
       
       
    
  </table> */}

   



        <table className="table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Item Name</th>
              <th>Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>

            {
              tableData.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td >{data.itemname}</td>
                    <td>{data.price}</td>
               
                    <td><button className="btn btn-outline-primary" data-item={data}
                      onClick={() =>  {BillSubmit(data)}} >Add Item +</button></td>
                  </tr>
                )
              })
            }

          </tbody>
        </table>
     
        {/* <form>
        <div class="col-md-6">
        <input type="text" name="name" placeholder="Enter Your Name" class="form-control" 
        id="inputEmail4" disabled={disabled} onChange={(e)=>setName(e.target.value)} value={name} />
        </div>
  </form> */}

      </>
    )
  }


}


export default Table;

