import React ,{useEffect, useState, useCallback, useContext} from 'react';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import Table from "./Table";
import SearchBar from './SearchBar';
import Modal from './Modal';
import Pagination from './Pagination';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from './App';
import { GiShoppingBag } from "react-icons/gi";
import Category from './Category';
import axios from 'axios';
import { faL } from '@fortawesome/free-solid-svg-icons';

let baseURL = '';

  if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:5000';
  } else {
    baseURL = 'http://83.223.113.92:5000';
  }

function Menu() {
    const navigator = useNavigate();
const [tableData, setTableData] = useState([])
 const [formInputData, setformInputData] = useState(
     {
     itemname:'',
     price:'',
     Categories:'',
     file:''
    }
 );

 const [open,setOpen] = useState(false);

 const [file,setFile] = useState();

 const [imagePreview, setPreview] = useState();

 let search = window.location.search;
  let params = new URLSearchParams(search);
 // User is currently on this page
const [currentPage, setCurrentPage] = useState(
  parseInt("" + params.get("page"))
);

//console.log(currentPage)



localStorage.setItem("PageNumber", currentPage );


const [recordsPerPage] = useState(5);


 const handleChange=(evnt)=>{  
     const newInput = (data)=>({...data, [evnt.target.name]:evnt.target.value})
    setformInputData(newInput)
 }

 const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

 const handlePhoto = (e) =>{
 
  setformInputData({...formInputData, file:e.target.files[0]});
 }

//  const params = useParams();
// const pageNumber = params.currentPage ? parseInt(params.currentPage, 10) : 1;
// console.log(pageNumber)
const page = currentPage;

    useEffect(()=>{
        const fetchData = async() => {
          
            const response = await fetch(baseURL+'/api/menu');
            const json = await response.json();
           // console.log(json)
            setTableData(json.data);
    
        }
    
        fetchData();
    }, [currentPage]);

    const query = localStorage.getItem('query');
    
    const nPages = Math.ceil(
      tableData.length / recordsPerPage
    );
  
 const handleSubmit= async(evnt) =>{
     evnt.preventDefault();
     const checkEmptyInput = !Object.values(formInputData).every(res=>res==="")
     if(checkEmptyInput)
     {
      const newData = (data)=>([...data, formInputData])
      setTableData(newData);
      const emptyInput= {itemname:'', price:''}
      setformInputData(emptyInput)
      //window.location.reload();
     }
        

 }  
const [Img,setImg] = useState('');
 

const saveData = async(e) =>{
  e.preventDefault();
 const formData = new FormData();
 formData.append('file', formInputData.file);
 formData.append('itemname', formInputData.itemname);
 formData.append('price', formInputData.price);
 formData.append('Categories', formInputData.Categories);

 //console.log(formInputData.file);
  await axios.post(baseURL+'/api/menu', formData)
 .then(res => {
  //console.log(res)
  setTableData(res)

  setOpen(false);
    window.location.replace(`/menu?page=${currentPage}`);
 })
 .catch(err => console.log(err))
  
//     const {itemname, price,Categories } = formInputData;
// const result = await fetch(`http://localhost:5000/menu`,{
//    method:'POST',
//    headers:{
//     "Content-Type":"application/json",
//     "Content-Type": "multipart/form-data",
//   },
//    body: JSON.stringify({
//        itemname,price,Categories
//    })
// })

// const data = await result.json();
// console.log(data);
//      if(data){
//         setOpen(false)
//        window.location.replace(`/menu?page=${currentPage}`);
//      }

}

const indexOfLastRecord = currentPage * recordsPerPage;
const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
const currentRecords = tableData.slice(indexOfFirstRecord, 
  indexOfLastRecord);


 //console.log(currentRecords)

// useEffect(()=>{
//   const getImg = async(e) =>{
//    await axios.get('http://localhost:5000/menu')
//     .then(res => setImg(res.data[0].image))
//     .catch(err => console.log(err));
//   }
//   getImg();
// },[])

 const removeData = async(index) =>{
  // console.log(index)
  setTableData(tableData.filter((contact) => contact._id !== index));
     if(window.confirm("Are you sure to Delete this Item")){
      const res = await fetch(baseURL+'/api/del/'+index,{
        method:'DELETE'
      })
      const data = await res.json();
      console.log(data);
      if(data){
        window.location.replace(`/menu?page=${currentPage}`);
     }
    }else {
      window.location.replace(`/menu?page=${currentPage}`);
    }
  }

  const [billItem,setBillItem] = useState([]);

  useEffect(()=>{
    const getItem = async() =>{
      const res = await fetch(baseURL+"/api/order");
      const result = await res.json();
      setBillItem(result);
    }
    getItem();
  },[])

  const gotocart = () =>{
    navigator('/cart')
  }
  
  const login = localStorage.getItem('loginuser')
 // console.log(login)


const calc =  billItem.filter((el) => el.loginuser  === login).length


 if(localStorage.getItem('role') === 'true'){
    return(
        <> 
        <div className="center">
            <h2 class="text-center" >Menu Items </h2>
            <div className="container">
            <div className="row">
             
<form method="POST" onSubmit={handleSubmit} encType='multipart/form-data'>

<SearchBar setTableData={setTableData} recordsPerPage={recordsPerPage}  setCurrentPage={setCurrentPage} />

{/* <FormInput handleChange={handleChange} formInputData={formInputData} handleSubmit={handleSubmit} /> */}

         <Table tableData={currentRecords}  Img={Img}
         removeData={removeData} handleChange={handleChange} currentPage={currentPage} formInputData={formInputData} setformInputData={setformInputData} />
       
         <button className="btn btn-outline-primary" onClick={()=>setOpen(true)}>Add</button>
         <Pagination
    nPages={nPages}
    currentPage={currentPage}
    setCurrentPage={setCurrentPage}
/>
         {open && <Modal handleChange={handleChange} imageUploader={imageUploader} handlePhoto={handlePhoto} formInputData={formInputData} saveData={saveData} CloseModal={()=>{setOpen(false)}}/>}
         </form>
       </div>
       </div>
       </div>


        </>
    )
 }
 else if(localStorage.getItem('role') === 'false'){
    return(
        <>
      

        <div className="center">
            <h2 class="text-center">Menu Items
            <div class="cart-container float-end"><span class="cart" style={{color: "white"}}>{calc}</span>
<GiShoppingBag className="cart-img" onClick={gotocart} />
</div>
            </h2>
           
            <div className="container">
            <div className="row">
          <main>
            <div>
            <SearchBar setTableData={setTableData} setCurrentPage={setCurrentPage} />
            </div>
<br />
<br />
      
        {/* <Category tableData={tableData} /> */}
      
      <Table imagePreview={imagePreview} tableData={currentRecords} setTableData={setTableData}  currentPage={currentPage} />
      <Pagination
    nPages={nPages}
    currentPage={currentPage}
    setCurrentPage={setCurrentPage}
/>
</main>
        </div>
        </div>
        </div>
        </>
    )
 }
   
}
export default Menu;



// https://phpgurukul.com/restaurant-table-booking-system-using-php-and-mysql/