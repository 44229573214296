import React, { createContext, useReducer } from 'react';
import Menu from './Menu';
import Signup from './Signup';
import Login from './Login';
import TableManagement from './TableManagement';
import AddTable from './AddTable';
import Cart from './Cart';
import Invoice from './Invoice';
import Category from './Category';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import {reducer,initialState} from './reducer/useReducer';

export const UserContext = createContext();

function App() {

  const Routing = () =>{
    return (
      <>
      {/*<BrowserRouter basename='/'>*/}
      <BrowserRouter>
     <Routes>
     <Route exact path="/" element={<Signup />}></Route>
     <Route exact path="/login" element={<Login />}></Route>
      <Route exact path="/menu" element={<Menu />}></Route>
      <Route exact path="/tablemanage" element={<TableManagement />}></Route>
      <Route exact path="/addtable" element={<AddTable />}></Route>
      <Route exact path="/cart" element={<Cart />}></Route>
      <Route exact path="/invoice" element={<Invoice />}></Route>
      <Route exact path="/Category" element={<Category />}></Route>
     </Routes>
     </BrowserRouter>
      </>
    )
  }

  const [State, dispatch] = useReducer(reducer, initialState);
  return(
    <>
    <UserContext.Provider value={{State,dispatch}} >
      <Routing />
      </UserContext.Provider>
    </>
  )
  
}

export default App;
