import React, { useState } from "react";

let baseURL = '';

  if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:5000';
  } else {
    baseURL = 'http://83.223.113.92:5000';
  }

function AddTable(){
    const [user, setUser] = useState({addtable:''});

    const handleChange = (e) =>{
        setUser({...user,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        //console.log(tableno)
        const {addtable} = user;
        const res = await fetch(baseURL+'/api/table',{
            method:'POST',
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({addtable})
        })
        const data = await res.json();
        console.log(data);
    }
    return(
        <>
        <form class="center" method="POST" action="/table" style={{width:"35%"}} onSubmit={handleSubmit}>
  <div class="mb-3">
    <label for="tableno" class="form-label">Add Table</label>
    <input type="text" class="form-control" id="tableno" name="addtable" value={user.addtable} onChange={handleChange} aria-describedby="emailHelp"/>
  </div>
  <button type="submit" class="btn btn-primary">Submit</button>
</form>
        </>
    )
}
export default AddTable;