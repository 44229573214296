import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './index.css';

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    const history = useNavigate();
    const pageNumbers = [];
    for (let i = 1; i <= nPages; i++) {
        pageNumbers.push(i);
      }
      //console.log(currentPage)

    const goToNextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const goToPrevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    const paginate = (n) => {
        setCurrentPage(n);
        history({
          pathname: "/menu",
          search: "?page=" + n
        });
      };
    
   
    return (
        <nav>
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                       onClick={goToPrevPage}
                         >
                        
                        Previous
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                        <a onClick={() => paginate(pgNumber)}
                          className='page-link' style={{position:"inherit"}} 
                          >
                            
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="page-link" 
                       onClick={goToNextPage}
                        >
                        
                        Next
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination