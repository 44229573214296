import React from 'react';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';
function Modal({CloseModal,handleChange,formInputData,saveData,handlePhoto}){
    return(
        <>
<div className='modal-container' >
    {/* <h1>hey</h1> */}
    <div className='model'>
        
    <div className="form-row">
          <div className='col-12'>
            <input type="text" onChange={handleChange} value={formInputData.itemname} name="itemname" className="form-control"  placeholder="Item Name" autoComplete='off' />
          </div>
          <div className='col-12'>
            <input type="text" onChange={handleChange} value={formInputData.price} name="price" className="form-control" placeholder="Price" autoComplete='off' />
          </div>
          <div className='col-12'>
          <select name="Categories" className='form-control' value={formInputData.Categories} onChange={handleChange} >
            <option value="0">Categories</option>
            <option value="1">Fast Food</option>
            <option value="2">Sweets</option>
          </select>
          </div>
          {/* <div className='col-12'>
           <input type="file" name="file" onChange={handlePhoto} value={formInputData.img}  className="form-control" placeholder="Upload Picture of Food..."  />
          </div> */}
          <div className='col-12'>
            <input type="hidden" onChange={handleChange} value={formInputData.quantity} name="quantity" className="form-control" placeholder="quantity" />
          </div>
          <div className='col'>
            <input type="submit" onClick={saveData} className="btn btn-primary" />
            <button className='btn btn-light' onClick={CloseModal}>Close</button>
          </div>
         
        </div>
        
    </div>
   
</div>
        </>
    )
}
export default Modal;


                