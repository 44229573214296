import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function TableManagement(){
  const [data,setData] = useState([]);
 const [del,seDel] = useState([])

 useEffect(()=>{
 const fetchData = async() =>{
 const res = await fetch('http://localhost:5000/table');
 const result = await res.json();
 setData(result);
 
 }
 fetchData();
 },[]);

 const deleteTableRows = async(index) =>{
     const rows = [...data];
     rows.splice(index, 1);
     setData(rows);
     if(window.confirm("Are you sure to remove this row")){
        const res = await fetch("http://localhost:5000/delete/"+index,{
            method:"DELETE"
        })
        const data = await res.json();
        console.log(data);

        if(data){
            window.location.reload();
        }
     }
        
 }
    return(
        <>
        <h2 class="text-center">Manage Tables</h2>
       <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Table No.</th>
      <th scope="col">Added By</th>
      <th scope="col">Creation DATE</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
   {
    data.map((d,index)=>{
        return(
            <tr key={index}>
            <td>{index+1}</td>
            <td>{d.addtable}</td>
            <td>Admin</td>
            <td>{d.createdat}</td>
            <td><button className="btn btn-outline-danger" onClick={()=>(deleteTableRows(d._id))}>x</button></td>
        </tr>
        )
      
    })
   }
  </tbody>
</table>
        </>
    )
}
export default TableManagement;


// https://codepen.io/maitrikjpatel/pen/dyoPLXY?editors=1011