import React, { useEffect, useState } from 'react';
// import pizza from './pizza.jpg';
// import sandwich from './sandwich.jpg';
// import fries from './fries.jpg';

import './index.css'


let baseURL = '';

  if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:5000';
  } else {
    baseURL = 'http://83.223.113.92:5000';
  }

function Category(){
const [tableData,setTableData] = useState([]);
useEffect(()=>{
  const getItem = async(e) =>{
    const result = await fetch(baseURL+"/api/menu");
    const res = await result.json();
    setTableData(res)
    
  }
  getItem();
})

const found = tableData.filter(obj => {
  return obj.Categories === '1';
});


      
return(
    <>
        
    {found.map((data,index) => {
        return (
          <div class="container">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4">
            <div class="col"><img className='catImg' src={'/demo123/restaurant/images/'+data.file} alt="card_img" style={{maxWidth: '100%', height: 'a uto'}}/>
<h6>{data.itemname}</h6>
</div>
            
          </div>
        </div>
        )
      })} 

   
     
    </>
)
}
export default Category;