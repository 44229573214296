import React, { useEffect,useRef,useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { v4 as uuid } from "uuid";


let baseURL = '';

  if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:5000';
  } else {
    baseURL = 'http://83.223.113.92:5000';
  }

function Invoice(){
  const pdfRef = useRef();
  const unique_id = uuid();
 
  // Get first 8 characters using slice
  const small_id = unique_id.slice(0, 8);
  const navigate = useNavigate();
    // const print = () =>{
    //     window.print();
    // }
  const [billItem,setBillItem] = useState([]);
  const [Bill,setBill] = useState([]);

  useEffect(()=>{
    const getItem = async(e) =>{
      const res = await fetch(baseURL+"/api/order");
      const result = await res.json();
      setBillItem(result);
    }
    getItem();
  },[])

  useEffect(()=>{
    const getBill = async(e) =>{
      const res = await fetch(baseURL+"/api/bill");
      const result = await res.json();
      setBill(result);
    }
    getBill();
  },[])

  const loginuser = localStorage.getItem('loginuser');

  const [total, setTotal] = useState(0);
  const calculateTotal = (item) => {
    return item.price * item.quantity;
  };
  
  useEffect(() => {
    let newTotal = 0;
    billItem.map(
      (total, item) => (total.loginuser === loginuser ? newTotal += total.price * total.quantity
      : null)
     
    )
    setTotal(newTotal);
    
  }, [billItem]);

  // const increase = (index) => {
  //   const temp = [...billItem];
  //   temp[index].quantity = temp[index].quantity + 1;
  //   setBillItem(temp);
     
  // };
  
  // const decrease = (index) => {
  //   const temp =  [...billItem];
  //   if(temp[index].quantity > 1){
  //     temp[index].quantity = temp[index].quantity - 1;
  //   }
  //   setBillItem(temp);
   
  // };
  
  
  // const handleChangeRow = (e) =>{
   
  //   setBillItem(e.target.value)
    
  // }
  // const getRandomId = (min = 0, max = 500000) => {
  //   min = Math.ceil(min);
  //   max = Math.floor(max);
  //   const num =  Math.floor(Math.random() * (max - min + 1)) + min;
  //   return num;
  // };
  
  // console.log(getRandomId());

  const downloadPdf = () =>{
    const input = pdfRef.current;
    html2canvas(input).then((canvas)=>{
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jspdf('p','mm','a4',true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imageWidth , pdfHeight / imageHeight);
      const imgX = (pdfWidth - imageWidth * ratio) / 2 ;
      const imgY = 30;
      pdf.addImage(imgData, 'PNG', imgX, imgY, imageWidth * ratio, imageHeight * ratio);
      pdf.save('invoice.pdf');
    })
  }
  

    return(
        <>
        <form style={{width:"50%", marginLeft: "auto",marginRight: "auto"}}>
        <div ref={pdfRef}>
        <h1 class="float-start">INVOICER</h1>
    {/* <button class="float-end btn btn-primary" onClick={print} >Print</button> */}
        
        <br />
        <br /><br />

        <div class="float-end">
        <h2>Restaurant Name</h2>
        
        </div>
        <br /><br /><br/>

        {/* <div class="float-start">
       {Bill.map(data => (data.loginuser === loginuser ? <h2>{data.name}</h2> : null))}
        </div> */}

        <div class="float-start">
          <h5>Invoice No. {small_id}</h5>
          <p>Customer's Name</p>
        </div>

        <div>
        <table className="table">
            <thead>
                <tr>
               
                    <th>Item Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
               
  {
                        billItem.map((data, index)=>
                          (data.loginuser === loginuser ? 
                            <tr key={index}>
                            
                                <td className="bill">{data.itemname}</td>
                                <td className="bill">{data.quantity}</td>
                                <td className="bill">{data.price}</td>
                                <td className="bill">{calculateTotal(data)}</td>
                          
                            </tr>
                        : null)
                            
                        )
                    }    

                     <tr>
      <th id="total" colSpan="3">Total Amount:</th>
      <td className="bill">{total}</td>
    </tr>
    </tbody>
        </table>
        </div>
        </div>
        
        </form>
        <div style={{width:"50%", marginLeft: "auto",marginRight: "auto"}}>
          <button className="btn btn-primary" onClick={()=>navigate('/cart')}>Update Invoice</button>
         
     <button className="btn btn-primary" style={{marginLeft:"5px"}} onClick={downloadPdf}>Save Invoice</button>
        </div>
        </>
    )

  
}
export default Invoice;