import React, {useContext, useState} from "react";
import { UserContext } from "./App";
import { useNavigate } from "react-router-dom";
const SearchBar = ({setTableData, setCurrentPage, recordsPerPage}) => {
  const navigator = useNavigate();
  const [table,setTable] = useState([])
    const [query, setQuery] = useState("");
    const page = localStorage.getItem("PageNumber");
    // console.log(page  )
    
    let baseURL = '';

  if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:5000';
  } else {
    baseURL = 'http://83.223.113.92:5000';
  }

    const fetchData = async(value) =>{
     const res = await fetch(baseURL+'/api/menu');
     const resp = await res.json();
     setTable(resp.data)
     
      
        const result =  table.filter((user)=>{
            return user && user.itemname && user.itemname.toLowerCase().includes(value)||
                  user && user.price && user.price.toString().toLowerCase().includes(value)
          })
          //console.log(result)
          setTableData(result)
        
        
     //   localStorage.setItem('query',result);
       // console.log(result)
      
    }
    const Search = (e) => {
  setQuery(e);
  setCurrentPage(1);
  
    fetchData(e);
  
};
// console.log(Search)

const {state, dispatch} = useContext(UserContext);
const handleLogout = () =>{
  localStorage.clear();
  dispatch({ type:"USER", payload: false});
  navigator('/login');
}

    return (
     <>
      <button className="btn btn-outline-danger" onClick={handleLogout}>Logout</button>
           <div class="searchbar float-end">
      <input type="text" placeholder="Search" class="form-control search" onChange={(e)=>Search(e.target.value)} value={query} />
 
    </div>
     </>
     
      
    );
  };
  export default SearchBar;
  
  
  
  //React how to run a function only once, after page enter or refresh