import React from "react";

function EditModal({handleChange, close, rowData, handleSubmit}){
 
return (
   <>
    <div className='modal-container' >
    {/* <h1>hey</h1> */}
    <div className='model EditModal'>
        <h3>Edit Item</h3>
    <div className="form-row">
          <div className='col-12'>
          <input type="text" className='form-control' name="itemname" defaultValue={rowData?.itemname} onChange={handleChange} autoComplete='off' />
          </div>
          <div className='col-12'>
          <input type="text" className='form-control' name="price" defaultValue={rowData?.price} onChange={handleChange} autoComplete='off' />
          </div>
          <div className='col-12'>
          <select name="Categories" className='form-control' defaultValue={rowData?.Categories} onChange={handleChange} >
            <option value="0">Categories</option>
            <option value="1">Fast Food</option>
            <option value="2">Sweets</option>
          </select>
          </div>
          
          <div className='col'>
            <input type="submit" value="Update" onClick={handleSubmit} className="btn btn-success" />
            <button className='btn btn-light' onClick={close}>Close</button>
          </div>
         
        </div>
        </div>
        </div>
   </>
    )
}

export default EditModal;